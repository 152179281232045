import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button, ButtonGroup } from "clutch/src/Button/Button.jsx";
import { mobileMedium } from "clutch/src/Style/style.mjs";

import { readState } from "@/__main__/app-state.mjs";
import { appURLs } from "@/app/constants.mjs";
import AccountsLol from "@/hub-settings/AccountsLol.jsx";
import { updateTftSkinSettings } from "@/hub-settings/actions.mjs";
import {
  PageContainer,
  Panel,
  SettingsContent,
} from "@/hub-settings/Common.style.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default function TFTSettings() {
  const { t } = useTranslation();
  const state = useSnapshot(readState);
  const isSkinsEnabled = state.settings.tft.isSkinsEnabled;
  const handleSkinFeature = (boolean) => () => updateTftSkinSettings(boolean);
  const championSkinsTranslation = t(
    "common:settings.tft.championSkinsTitle",
    "Use Skin Artwork for Champion Portraits",
  );
  const championSkinsImage = `${
    appURLs.CDN
  }/blitz/tft/settings/settingsTftSkinSettings${
    isSkinsEnabled ? "" : "Disabled"
  }.png`;
  return (
    <PageContainer>
      <AccountsLol game="tft" />
      <PanelStyles>
        <Panel className={"panel"}>
          <img
            alt={championSkinsTranslation}
            src={championSkinsImage}
            width="440px"
            height="264px"
            className={"hero-img"}
          />
          <SettingsContent className={"settings-content"}>
            <h5 className="type-article-headline">
              {championSkinsTranslation}
            </h5>
            <div className={"type-caption shade2"}>
              {t(
                "common:settings.tft.championSkinsSubtitle",
                "Use TFT set skin artwork when displaying/representing champion images.",
              )}
            </div>
            <ButtonGroup>
              <Button onClick={handleSkinFeature(true)} active={isSkinsEnabled}>
                {t("common:enabled", "Enabled")}
              </Button>
              <Button
                onClick={handleSkinFeature(false)}
                active={!isSkinsEnabled}
              >
                {t("common:disabled", "Disabled")}
              </Button>
            </ButtonGroup>
          </SettingsContent>
        </Panel>
      </PanelStyles>
    </PageContainer>
  );
}

const PanelStyles = styled("div")`
  .panel {
    flex-direction: row !important;

    ${mobileMedium} {
      flex-direction: column !important;
    }

    .hero-img {
      width: 220px;
    }

    .settings-content {
      display: flex;
      flex-direction: column;
      gap: var(--sp-3);
    }
  }
`;
